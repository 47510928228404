import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

/* === COMPONENTS === */
import useFetch from "../useFetch"

    function CTA() {

        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/whistle-space?populate=deep`)

        let button = null;

        if (data) {

            button = data.data.attributes.CTA_Button

            return (
                <div className="intro-button-link">
                    { button.is_Visible ? (
                        <Link 
                            to={ button.Link } 
                           >
                            <button className="cta-button">
                                <h3>{ button.Call_To_Action  }</h3>
                            </button>
                        </Link>   ) : (null) }
                </div>
            );
        }
    }
    
    export default CTA;