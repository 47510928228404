import logo from './logo.svg';
import './App.css';

/* === COMPONENTS === */
import appHeight from './appHeight'
import Nav from './components/Nav';
import Footer from './components/Footer';

function App() {
  return (
      <Nav />
  );
}

export default App;
