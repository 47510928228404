import { useState, useEffect } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

/* === COMPONENTS === */
import useFetch from "../useFetch"

    function SubNav() {

        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/whistle-space?populate=deep`)
        const [open, setOpen] = useState(false)

        let subNav = null;

        const menuToggle = () => {
     
            if (open == true) {
                if (window.innerWidth < 900 ) {
                    document.getElementById(`subnav`).style.width = `58px`
                    setOpen(false)

                } else {
                    document.getElementById(`subnav`).style.width = `78px`
                    setOpen(false)
                }
            }    

            if (open == false) {
                document.getElementById(`subnav`).style.width = `100%`
                setOpen(true)
            }       
        }

        const menuShrink = () => {
     
            if (open == true) {
                document.getElementById(`subnav`).style.width = `98%`
            }    

        }

        const menuGrow = () => {
     
            if (open == true) {
                document.getElementById(`subnav`).style.width = `100%`
            }    

        }

        const initialSet = () => {
     
            document.getElementById(`subnav`).style.width = `100%`
            setOpen(true)
        }

      
        useEffect(() => {
            const timer = setTimeout(() => initialSet(), 5900);
          }, []);

        if (data) {

            subNav = data.data.attributes.Whistle_Space_Sections.data

            // window.setTimeout( () => {
            //     console.log('timeout')
            // }, 6000); 

            return (
                <div className="subnav-wrapper" >
                    <div id="subnav">
                        <div 
                            className="subnav-glyph-container"
                            onClick={() => menuToggle()}
                            onMouseEnter={() => menuShrink()}
                            onMouseLeave={() => menuGrow()}
                            >                         
                            <svg className="subnav-glyph" viewBox="0 0 143 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M143 0H0.000335693V87H0.961243L37.9686 164H5.13802L0 169.833V223.259H142.995V169.833L137.857 164H105.036L142.039 87H143V0ZM48.5416 164L71.501 116.224L94.4627 164H48.5416Z" fill="#E7E5D9"/>
                            </svg>

                        </div>
                        <div className="subnav-link-container">
                            {subNav.map((title, index) => 
                                <a href={`#${ title.attributes.Section_Title }`}>
                                    <h3>{ title.attributes.Section_Title }</h3>
                                </a>
                            )}
                        </div>
                    </div>
                </div>    
            );
        }
    }
    
    export default SubNav;