import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

/* === COMPONENTS === */
import CTA from './CTA';

    function WhistleSpaceIntro() {

        return (
            <div className="section-wrapper intro-section" >
               <svg className="whistle-space-wordmark" width="1298" height="326" viewBox="0 0 1298 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="whistle-space-wordmark-animation-seven" d="M1294.45 38.2632H1158.77V96.9793H1294.45V38.2632Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-seven" d="M1158.77 134.855H1294.45V193.541H1158.77V134.855Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-six" d="M965.653 193.541H1101.33V0.387207H965.653V193.541Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-five" d="M802.413 46.6007V96.9793H773.921L735 74.5081V0H775.508L802.413 46.6007Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-five" d="M772.475 134.855L735 156.492V231H780.508L802.413 193.06V134.855H772.475Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-five"d="M840.33 29.7255L857.492 0H908V75.0855L870.079 96.9793H840.33V29.7255Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-five" d="M871.525 134.855H840.33V192.614L862.492 231H908V155.915L871.525 134.855Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-four" d="M683.215 193.541L541.441 66.7553V151.5L588 193.541H683.215Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-four" d="M572.935 38L715.036 165.079V80L665 38H572.935Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-three" d="M416.118 1.77163L382.5 60L348.294 0.753377V231.417H416.118V1.77163Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-three" d="M487.5 172L454.035 229.963V0.387207H521.889V231.417H521.804L487.5 172Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-two" d="M291 134.855V231.417H193.064V134.855H291Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-two" d="M291 0.387207V96.9793H193.064V0.387207H291Z" fill="white"/>
                    <path className="whistle-space-wordmark-animation-one" clip-rule="evenodd" d="M143 7H0.000335693V94H0.961243L37.9686 171H5.13802L0 176.833V230.259H142.995V176.833L137.857 171H105.036L142.039 94H143V7ZM48.5416 171L71.501 123.224L94.4627 171H48.5416Z" fill="white"/>
                    <g className="whistle-space-wordmark-animation-eight">
                        <path d="M19.6697 324.207H11.7978L0 280.794H5.75724L10.9985 302.134C12.4555 308.061 13.7708 313.708 14.9344 319.565H16.9783C18.2937 313.487 19.4572 308.202 20.9851 302.204L26.8132 280.794H33.6632L39.4912 302.204C40.9483 307.921 42.1827 313.637 43.4272 319.565H45.3901C46.4829 313.778 47.8691 308.061 49.2553 302.134L54.5673 280.794H60.102L48.294 324.196H40.2805L35.5451 306.112C34.0881 300.395 31.9734 291.856 30.739 285.637H29.2112C27.8958 291.926 25.8621 300.395 24.405 306.112L19.6697 324.196V324.207Z" fill="white"/>
                        <path d="M171.816 324.207V304.886H146.53V324.207H141.289V280.794H146.53V300.184H171.816V280.794H177.057V324.196H171.816V324.207Z" fill="white"/>
                        <path d="M263.203 324.207V280.794H268.444V324.196H263.203V324.207Z" fill="white"/>
                        <path d="M351.535 310.382L356.564 309.297C357.798 316.601 362.028 320.58 370.406 320.58C378.055 320.58 381.333 317.545 381.333 312.984C381.333 308.423 378.278 306.112 369.313 304.525C357.434 302.425 353.579 298.014 353.579 291.715C353.579 284.622 358.749 279.99 369.1 279.99C379.451 279.99 383.741 285.054 385.492 292.87L380.251 293.955C378.794 287.666 376.173 284.411 368.888 284.411C362.038 284.411 358.76 287.083 358.76 291.424C358.76 295.402 361.087 298.075 371.073 300.034C383.387 302.345 386.595 306.614 386.595 312.623C386.595 319.997 381.859 324.99 370.274 324.99C358.324 324.92 352.79 319.133 351.555 310.372L351.535 310.382Z" fill="white"/>
                        <path d="M464.414 280.794H499.089V285.637H484.377V324.196H479.136V285.647H464.424V280.794H464.414Z" fill="white"/>
                        <path d="M586.327 319.424H607.383V324.196H581.086V280.794H586.327V319.424Z" fill="white"/>
                        <path d="M689.747 280.794H719.181V285.496H695.069V299.752H716.631V304.384H695.069V319.505H719.181V324.207H689.747V280.794Z" fill="white"/>
                        <path d="M800.801 310.382L805.829 309.297C807.064 316.601 811.293 320.58 819.671 320.58C827.32 320.58 830.599 317.545 830.599 312.984C830.599 308.423 827.543 306.112 818.578 304.525C806.7 302.425 802.845 298.014 802.845 291.715C802.845 284.622 808.015 279.99 818.366 279.99C828.717 279.99 833.007 285.054 834.757 292.87L829.516 293.955C828.059 287.666 825.439 284.411 818.153 284.411C811.303 284.411 808.025 287.083 808.025 291.424C808.025 295.402 810.352 298.075 820.339 300.034C832.653 302.345 835.86 306.614 835.86 312.623C835.86 319.997 831.125 324.99 819.54 324.99C807.59 324.92 802.055 319.133 800.811 310.372L800.801 310.382Z" fill="white"/>
                        <path d="M924.681 307.639V324.207H919.439V280.794H935.973C945.807 280.794 950.836 285.205 950.836 294.247C950.836 302.204 945.666 307.629 936.124 307.629H924.681V307.639ZM924.681 285.355V303.229H935.537C942.6 303.229 945.666 300.405 945.666 294.327C945.666 288.249 943.045 285.355 935.467 285.355H924.681Z" fill="white"/>
                        <path d="M1058.54 311.105H1037.92L1032.89 324.196H1027.36L1044.91 280.794H1051.76L1069.25 324.196H1063.64L1058.54 311.105ZM1039.53 306.986H1056.94L1054.83 301.631C1052.79 296.427 1050.6 290.128 1049.14 285.426H1047.46C1045.87 290.198 1043.68 296.347 1041.57 301.631L1039.52 306.986H1039.53Z" fill="white"/>
                        <path d="M1144.98 302.425C1144.98 288.751 1152.63 280 1165.67 280C1176.67 280 1183.45 286.219 1185.05 296.135L1180.09 297.15C1178.41 289.193 1173.83 284.712 1165.66 284.712C1155.17 284.712 1150.22 291.805 1150.22 302.435C1150.22 313.064 1155.18 320.378 1165.52 320.378C1174.26 320.378 1178.71 315.747 1180.32 307.498L1185.34 308.513C1183.75 318.57 1176.97 325.01 1165.52 325.01C1152.26 325.01 1144.97 316.109 1144.97 302.435L1144.98 302.425Z" fill="white"/>
                        <path d="M1268.57 280.794H1298V285.496H1273.89V299.752H1295.45V304.384H1273.89V319.505H1298V324.207H1268.57V280.794Z" fill="white"/>
                    </g>
                </svg>
                <CTA />
            </div>    
        );
    }
    
    export default WhistleSpaceIntro;