import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

/* === COMPONENTS === */
import useFetch from "../useFetch"
import SubNav from '../components/subNav';
import WhistleSpaceIntro from '../components/WhistleSpaceIntro';
import Text from "../components/Text"
import Footer from '../components/Footer';

    function WhistleSpace() {

        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/whistle-space?populate=deep`)

        let sections = null;
        let display = [];

        if (data) {

            sections = data.data.attributes.Whistle_Space_Sections.data

            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].attributes.Section[0].__component === "components.text") {
                    display.push(<Text data={sections[i]} />)
                }
            }

            return (
                <div className="page-wrapper">
                    <SubNav />
                    <WhistleSpaceIntro />
                    {display.map((section, index) => 
                        <div key={ index }>
                            { section }
                        </div>
                    )}
                    <Footer />
                </div>
            );
        }
    }
    
    export default WhistleSpace;